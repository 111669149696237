import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import resources from '../../../resources';
import { noop } from '../../../utils';
import {pixels} from '../../../config/breakpoints';

const modal = resources.copy.email;

const ModalWrapper = styled.div`
  position: fixed; /* Stay in place */
  z-index: 2000; /* Sit on top */
  padding-top: 100px; /* Location of the box */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0,0,0); /* Fallback color */
  background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
`

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #fefefe;
  margin: auto;
  padding: 40px;
  border: 1px solid #888;
  width: 100%;
  @media(min-width: ${pixels.tablet}) {
    width: 70%;
  }
`

const ModalLabel = styled.label`
  font-size: 16px;
  margin: 20px 0 10px 0;
`

const ModalButton = styled.button`
  margin: 40px auto;
  background-color: #31576e;
  color: white;
  padding: 20px;
  width: 100%;
  align: center;
  font-size: 16px;
  display: block;
  border: none;
  border-radius: 5px;
  
  &:focus, &:active {
    color: #555555;
  }
  @media(min-width: ${pixels.tablet}) {
    width: 40%;
  }
  
`
const ModalInput = styled.input`
  border: 1px solid #979797;
  height: 60px;
  font-size: 20px;
  padding: 0 15px
`

const VerificationText = styled.span`
  font-size: 13px;
  color: red;
  margin-bottom: 5px;
`
const OptOut = styled.a`
  /* No thanks, remind me: */
  font-size: 16px;
  color: #212121;
  letter-spacing: 0;
  text-align: center;
  text-decoration: underline;
  
  &:focus, &:active, &:hover {
    color: #555555;
    text-decoration: none;
  }
`
class Modal extends Component {

  static propTypes = {
    onClose: PropTypes.func,
    setPersonalData: PropTypes.func,
    optOut: PropTypes.bool,
  }

  static defaultProps = {
    onClose: noop,
    setPersonalData: noop,
    optOut: true,
  }

  constructor() {
    super();
    this.state = {
      name: '',
      email: '',
      emailVerified: true,
      nameVerified: true,
    }
  }


  handleChangeEmail = (e) => {
    this.setState({
      email: e.target.value,
    })
  }

  handleChangeName = (e) => {
    this.setState({
      name: e.target.value,
    })
  }

  handleSubmit = () => {
    if (this.verifyInput()) {
      this.props.setPersonalData({
        'name': this.state.name,
        'email': this.state.email
      });
      this.props.onClose();
    }
  }

  verifyInput = (requireName) => {
    const email = (/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/).test(this.state.email);
    const name = (/^[A-Za-z0-9]{2,}$/).test(this.state.name);
    this.setState({ emailVerified: email });
    this.setState({ nameVerified: name });
    return email && name;
  }

  closeModal = (el) => {
    this.props.onClose();
    document.querySelector('#result').scrollIntoView(true)
    var scrolledY = (typeof window !== 'undefined') ? window.scrollY : 80;
    if(scrolledY){
      if (typeof window !== 'undefined') {
        window.scroll(0, scrolledY - 80);
      }
    }
  }

  render() {
    return (
      <ModalWrapper>
        <ModalContent>
          <p style={{ fontSize: 40, margin: 0 }}>{modal.title}</p>
          <p style={{ fontSize: 16, margin: '20px 0 0 0' }}>{modal.description}</p>
          <ModalLabel>{modal.name}</ModalLabel>
          {!this.state.nameVerified &&
            <VerificationText>
              Please enter your name (more than 2 characters)
            </VerificationText>}
          <ModalInput type="text" name="name" onChange={this.handleChangeName} />
          <ModalLabel>{modal.email}</ModalLabel>
          {!this.state.emailVerified &&
            <VerificationText>
              Please enter valid email
            </VerificationText>}
          <ModalInput type="text" name="email" onChange={this.handleChangeEmail} />
          <ModalButton onClick={this.handleSubmit}>Submit</ModalButton>
          {this.props.optOut && <OptOut onClick={this.closeModal}>No thanks, remind me later</OptOut>}
        </ModalContent>
      </ModalWrapper>
    )
  }
}

export default Modal;
