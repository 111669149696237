import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import StoneColorCheckBoxGroup, { StoneColorCheckbox } from './StoneColorSelectGroup';
import ShowMore from './showMore';
import { noop } from '../../../utils';

import resources from '../../../resources';

const { stoneColorSet: errorMsg } = resources.copy.error;

const StoneColorWrapper = styled.div`
  margin-bottom: 5px;
`
const StoneColorHeading = styled.div`
  margin-bottom: ${props => (props.error
    ? 0
    : '14px')};
  font-size: 20px;
`
const Error = styled.p`
  color: red;
  font-size: 13px;
  margin: 0 0 5px;
`
const StoneColorSelector = ({
  colorStone,
  selectedStoneColors,
  stoneClick,
  showMore,
  showMoreClick,
  heading,
  error,
}) => (
  <StoneColorWrapper>
    <StoneColorHeading error={error}>
      {heading}
    </StoneColorHeading>
    {error && <Error>{errorMsg}</Error>}

    <StoneColorCheckBoxGroup valueName="stoneColor" showMore={showMore}>
      {
        colorStone.map((s, i) => <StoneColorCheckbox
          color={`#${s.productHex}`}
          isSelected={selectedStoneColors[i] === 1}
          key={i}
          value={i}
          colorClick={stoneClick} >
          {s[i]}
        </StoneColorCheckbox>)
      }
    </StoneColorCheckBoxGroup>

    <ShowMore onClick={showMoreClick} showMore={showMore} />

  </StoneColorWrapper>
)

StoneColorSelector.propTypes = {
  colorStone: PropTypes.array,
  selectedStoneColors: PropTypes.array,
  stoneClick: PropTypes.func,
  showMore: PropTypes.bool,
  showMoreClick: PropTypes.func,
  heading: PropTypes.string,
  error: PropTypes.bool,
}

StoneColorSelector.defaultProps = {
  colorStone: [],
  selectedStoneColors: [],
  stoneClick: noop,
  showMore: false,
  showMoreClick: noop,
  heading: '',
  error: false,
}

export default StoneColorSelector;
