import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Slider from 'rc-slider';
import resources from '../../../resources';
import { noop } from '../../../utils';

import 'rc-slider/assets/index.css';

const { dimensionSet: errorMsg } = resources.copy.error;

const DimensionSelectorWrapper = styled.div`
  // border: 1px solid black;
  margin-bottom: 30px;
`

const DimensionHeading = styled.p`
  /* Dimensions in square: */
  font-size: 20px;
  color: #31576e;
  letter-spacing: 0.3px;
`
const DimensionInput = styled.input`
  padding: 10px;
  width: 100%;
  box-sizing: border-box;
  /* Field: */
  border: 2px solid #DFE4EE;
  box-shadow: 0px 2px 4px #ffffff ;
`
const Error = styled.p`
  color: red;
  font-size: 13px;
  margin: 5px 0 15px;
  opacity: ${props => (props.opacityValue
      ? 1
      : 0)}
`
const sliderStyle = {
  width: '100%',
  margin: '10 0',
  height: 20,
}

const DimensionSelector = ({
  heading,
  dimension,
  handleTextChange,
  handleSlider,
  error,
}) => <DimensionSelectorWrapper>
    <DimensionHeading id="dimension">{heading}</DimensionHeading>
    <DimensionInput
      type="text"
      for={"dimension"}
      value={dimension}
      onChange={handleTextChange} />
    <Error opacityValue={error}>{errorMsg}</Error>
    <Slider
      style={sliderStyle}
      className={'.rc-slider-rail'}
      value={dimension}
      min={0}
      max={200}
      handleStyle={{
        backgroundColor: '#31576e',
        border: 'none',
        boxShadow: '0 7px 20px 0 rgba(33, 33, 33, 0.4)',
        width: 20,
        height: 20,
      }}
      trackStyle={{ backgroundColor: '#31576e', height: 8}}
      railStyle={{ height: 8, backgroundColor: '#E3E8F0' }}
      onChange={handleSlider}
    />
  </DimensionSelectorWrapper>

DimensionSelector.propTypes = {
  heading: PropTypes.string,
  dimension: PropTypes.number,
  handleTextChange: PropTypes.func,
  handleSlider: PropTypes.func,
  error: PropTypes.bool,
}

DimensionSelector.defaultProps = {
  heading: '',
  dimension: 0,
  handleTextChange: noop,
  handleSlider: noop,
  error: false,
}

export default DimensionSelector;