import React from 'react';
import styled from 'styled-components';

import CalculatorContainer from './CalculatorContainer';

const AppWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`
export const App = ({showMenu}) => {
  return (
      <AppWrapper>
        <CalculatorContainer showMenu={showMenu}/>
      </AppWrapper>
  );
}
