import React from 'react';
import { connect } from 'react-redux';
import Results from '../component/results';
import { EMAIL_SENT } from '../constants/action-types';
import { savePersonalDataToStore } from '../actions';

const ResultsComponent = props => <Results {...props} />;
const mapStateToProps = state => ({
  quote: state.quoteSelection,
  name: state.name,
  email: state.email,
})


const mapDispatchToProps = dispatch => ({
  savePersonalData: (data) => dispatch(savePersonalDataToStore(data)),
  emailSent: () => dispatch({ type: EMAIL_SENT }),
})

export default connect(mapStateToProps, mapDispatchToProps)(
  ResultsComponent
);
