import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import HeroContent from './heroContent';
import ProductCalculator from './productCalculator';
import imgUrl from '../../assets/img/heroimage.jpg';
import {noop} from '../../utils';
import {pixels} from '../../config/breakpoints';

const HeroContainer = styled.div`
  width: 100%;
  height: auto;
`
const heightDiv = typeof window !== 'undefined' ? window.innerHeight - (2 * (window.innerHeight * 3 / 100)) - 80 : 0;

const HeroTop = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0;
  background-image: none;
  min-height: ${heightDiv}px;
  justify-content: space-evenly;
  @media(min-width: ${pixels.tablet}) {
    flex-direction: row;
    background-image: url(${imgUrl});
    background-repeat: no-repeat;
    background-position: center bottom; 
    background-size: cover;
    padding: 3vh 10vw;
  }
`

const HeroCalculator = styled.div`
  display: flex;
  justify-content: space-around;
  flex: 1 0 auto;
`


const HeroHeader = (props) => (
  <HeroContainer id="home">
    <HeroTop>
      <HeroContent/>
      <HeroCalculator>
        <ProductCalculator
          saveQuote={props.saveQuote}
          savePersonalData={props.savePersonalData}
          reset={props.reset}
          clearState={props.clearState}
        />
      </HeroCalculator>
    </HeroTop>
  </HeroContainer>
)


HeroHeader.propTypes = {
  saveQuote: PropTypes.func,
  savePersonalData: PropTypes.func,
  reset: PropTypes.bool,
  clearState: PropTypes.func,
}

HeroHeader.defaultProps = {
  saveQuote: noop,
  savePersonalData: noop,
  reset: false,
  clearState: noop,

}

export default HeroHeader;