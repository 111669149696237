import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { noop } from '../../../../utils';

const Group = styled.div`
  display: block;
`

const ProductRadio = styled.input`
  display: none;
`

const CustomRadio = styled.span`
  border: 2px solid #E4E7EF;
  border-radius: 50%;
  width: 16px;
  height: 16px;
  cursor: pointer;
  display: inline-block;
  margin: 0px 10px 0px -2px;
  box-sizing: content-box;
  &:after{
    content: '';
    display: inline-block;
    height: 8px;
    width: 8px;
    background-color: ${props => (props.checked
      ? '#000000'
      : '#ffffff')};
    border-radius: 50%;
    margin: 4px;
}
`
const ProductLabel = styled.label`
  /* Radiobutton: */
  font-size: 13px;
  color: #555555;
  letter-spacing: 0;
  &:first-child{
    margin-right: 25px;
  }
  margin-top: 10px;
  display: inline-flex;
`

export const ProductRadioButton = ({
  value = 0,
  children,
  name,
  productClick,
  checked,
}) => <ProductLabel>
    <ProductRadio
      type="radio"
      value={value}
      name={name}
      onChange={productClick}
      checked={checked}
    />
    <CustomRadio checked={checked} />
    {children}
  </ProductLabel>;

ProductRadioButton.propTypes = {
  value: PropTypes.string,
  children: PropTypes.node,
  name: PropTypes.string,
  productClick: PropTypes.func,
  checked: PropTypes.bool,
}

ProductRadioButton.defaultProps = {
  value: '',
  children: {},
  name: '',
  productClick: noop,
  checked: false,
}

const ProductRadioGroup = ({ children, valueName }) => {
  const childrenRadio = React.Children.map(children, child => React.cloneElement(child, {
    name: valueName,
  }));

  return <Group>{childrenRadio}</Group>;
}

ProductRadioGroup.propTypes = {
  children: PropTypes.node,
  valueName: PropTypes.string,
}
ProductRadioGroup.defaultProps = {
  children: {},
  valueName: '',
}

export default ProductRadioGroup;
