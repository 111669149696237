import React from 'react';
import PropTypes from 'prop-types';
import {Link} from 'gatsby'
import styled from 'styled-components';

import {pixels} from '../../../config/breakpoints';

const ContentMediaItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  @media (min-width: ${pixels.tablet}) {
    flex-direction: ${(props) => (props.reverse
            ? 'row-reverse'
            : 'row')};
  }
`
const ContentBlock = styled.div`
  width: 100%;
  @media (min-width: ${pixels.tablet}) {
    width: 50%;
    height: auto;
  }
`

const Img = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  overflow: hidden;
`

const DescriptionWrapper = styled.div`
  padding: 30px;
  @media (min-width: ${pixels.tablet}) {
    padding: 80px 90px;
  }
`

const DescriptionTitle = styled.div`
  font-size: 36px;
  font-weight: normal;
  color: #212121;

  @media (min-width: ${pixels.tablet}) {
    font-weight: 700;
    font-size: 40px;
    margin-bottom: 15px;
  }
`

const DesciptionSubTitle = styled.div`
  font-family: Lato;
  font-weight: 300;
  font-size: 18px;
  color: #212121;
  letter-spacing: 0;
  text-transform: uppercase;
  margin-bottom: 15px;
  @media (min-width: ${pixels.tablet}) {
    font-size: 16px;
  }
`

const DescriptionContent = styled.div`
  font-family: Lato;
  font-weight: 300;
  font-size: 16px;
  color: #212121;
  letter-spacing: 0;
  line-height: 26px;
  margin-bottom: 15px;
`

const LearnMoreLink = styled(Link)`
  /* Learn more: */
  font-family: Lato;
  font-size: 16px;
  font-weight: 700;
  color: #31576e;
  letter-spacing: 0;
  text-decoration: none;
  border: 1px solid #31576e;
  border-radius: 2px;
  padding: 10px 15px;
  
  &:active, &:hover {
    text-decoration: underline;
  }
`


const ContentMediaItem = ({reverse, resource, image}) => (
  <ContentMediaItemWrapper reverse={reverse}>
    <ContentBlock>
      <Img src={image} alt={'example picture'}/>
    </ContentBlock>
    <ContentBlock>

      <DescriptionWrapper>
        <DesciptionSubTitle>{resource.title}</DesciptionSubTitle>
        <DescriptionTitle>
          {resource.subtitle}
        </DescriptionTitle>
        <DescriptionContent>
          {resource.description}
        </DescriptionContent>
        <LearnMoreLink to={resource.learnMore}>Learn more</LearnMoreLink>
      </DescriptionWrapper>
    </ContentBlock>

  </ContentMediaItemWrapper>
)

ContentMediaItem.propTypes = {
  reverse: PropTypes.bool,
  resource: PropTypes.object,
  image: PropTypes.string,
}

ContentMediaItem.defaultProps = {
  reverse: false,
  resource: {},
  image: '',
}

export default ContentMediaItem;