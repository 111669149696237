import React, { Component } from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';

import resources from '../../resources';
import {pixels} from '../../config/breakpoints';

import image1 from '../../assets/img/showcase1.jpg';
import image3 from '../../assets/img/showcase2.jpg';
import image4 from '../../assets/img/showcase3.jpg';
import image2 from '../../assets/img/showcase4.jpg';

const { work } = resources.copy;
const images = [
  image1,
  image2,
  image3,
  image4
];

const ShowCasesWrapper = styled.div`
  display: flex;
  flex-direction: column;
`
const ShowCaseTitle = styled.div`
  /* Our Work: */
  font-size: 18px;
  color: #777777;
  letter-spacing: 0;
  line-height: 25px;
  text-transform: uppercase;
  @media (min-width: ${pixels.tablet}) {
    font-size: 16px;
  }
`

const ShowCaseDescription = styled.div`
  /* Showcase of our best: */
  font-size: 30px;
  color: #212121;
  letter-spacing: 0;
  line-height: 1.53;
  font-weight: bold;
  @media (min-width: ${pixels.tablet}) {
    font-size: 40px;
    line-height: 60px;
    font-weight: normal;
  }

`
const ShowCaseImages = styled.div`
  display: flex;
  flexdirection: row;
`
const ShowCaseText = styled.div`
  padding: 80px;
  text-align: center;
`

const ImageLink = styled(Link)`
  width: 100%;
  display: ${({index}) => index === 0 ? 'block' : 'none'};

  @media (min-width: ${pixels.mobile}) {
    display: ${({index}) => (index === 0 || index === 1 ) ? 'block' : 'none'};
    width: 50%;
  }
  
  @media (min-width: ${pixels.tablet}) {
    display: block;
    width: 25%;
  }
`

const Img = styled.img`
  width: 100%;
  height: 100%;
}
`
class ShowCases extends Component {

  render() {
    return (
      <div>
        <div  id="gallery" />
        <ShowCasesWrapper>
          <ShowCaseText>
            <ShowCaseTitle>
              {work.title}
            </ShowCaseTitle>
            <ShowCaseDescription>
              {work.subtitle}
            </ShowCaseDescription>
          </ShowCaseText>
          <ShowCaseImages>
            {
              images.map((img, i) => (
                <ImageLink  index={i}  key={i} to={`${this.props.internal
                  ? ''
                  : '/gallery'}`} >
                  <Img alt={'showcase image'} src={img} key={i}/>
                </ImageLink>
              ))
            }
          </ShowCaseImages>
        </ShowCasesWrapper>
      </div>
    )
  }
}

ShowCases.propTypes = {
  internal: PropTypes.bool,
}

ShowCases.defaultProps = {
  internal: false,
}

export default ShowCases;