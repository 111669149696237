import React from 'react'
import {Helmet} from 'react-helmet'

import Footer from '../footer'

import {withPrefix} from 'gatsby'
import {createGlobalStyle} from "styled-components";

const GlobalStyle = createGlobalStyle`
  * {
    font-family: 'Lato', sans-serif;
  }

  body {
    margin: 0;
    padding: 0;
    font-size: 15px;
  }
`
const TemplateWrapper = ({children}) => {
  const {title = 'Forma', description = ''} = {}
  return (
    <div>
      <GlobalStyle/>
      <Helmet>
        <html lang="en"/>
        <title>{title}</title>
        <meta name="description" content={description}/>
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href={`${withPrefix('/')}img/apple-touch-icon.png`}
        />
        <link
          rel="icon"
          type="image/png"
          href={`${withPrefix('/')}img/favicon-32x32.png`}
          sizes="32x32"
        />
        <link
          rel="icon"
          type="image/png"
          href={`${withPrefix('/')}img/favicon-16x16.png`}
          sizes="16x16"
        />
        <meta name="theme-color" content="#fff"/>

        <meta property="og:type" content="business.business"/>
        <meta property="og:title" content={title}/>
        <meta property="og:url" content="/"/>

      </Helmet>
      <div>{children}</div>
      <Footer/>
    </div>
  )
}

export default TemplateWrapper
