import React, {useState} from 'react';
import styled from 'styled-components';
import Modal from 'react-bootstrap/Modal';

import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form'

const Img = styled.div`
  background-image: url("/img/heroimage.jpg");
  /* Full height */
  height: 100vh;
  width: 100vw;

  /* Center and scale the image nicely */
  background-position: left;
  background-repeat: no-repeat;
  background-size: cover;
  font-weight: bold;
  font-size: 50px;
  padding: 20px;
`
const Login = styled.div`
  position: absolute;
  bottom: 0;
`

const Constuction = ({login}) => {
  const [smShow, setSmShow] = useState(false);
  const handleSubmit = () => {
    const password = document.getElementById('password').value;
    if(password === 'F0rm@Te$tP@ss') {
      login(true)
    } else login(false);
    setSmShow(false);
  };
  return(
    <>
      <Img>
        This site is under construction
      </Img>
      <Login><Button variant="link" onClick={() => setSmShow(true)}>Test</Button></Login>
      <Modal
        size="sm"
        show={smShow}
        onHide={() => setSmShow(false)}
        aria-labelledby="example-modal-sizes-title-sm"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-sm">
            Credentials
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit}>
            <Form.Group className="mb-3" >
              <Form.Control  id="password" type="password" placeholder="Password" />
            </Form.Group>
            <Button variant="primary" type="submit">
              Submit
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default Constuction;