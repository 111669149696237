import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {saveQuoteToStore, savePersonalDataToStore, clearState} from '../actions';
import {ACCEPT_COOKIES} from '../constants/action-types';
import HeroHeader from '../component/heroHeader';
import Menu from '../component/menu';
import ContentPanel from '../component/contentPanel';
import ResultsContainer from '../containers/ResultsContainer';
// import CookieDisclaimer from '../component/cookieDisclaimer';

const HeroHeaderComponent = props => (
  <div>
    {/* {!props.cookieSet && <CookieDisclaimer handleCookies={props.getCookiesConsent} />} */}
    <HeroHeader {...props} />
    {props.showMenu && <Menu />}
    {props.quoteReceived && <ResultsContainer clearState={props.clearState}/>}
    <ContentPanel/>
  </div>)

HeroHeaderComponent.propTypes = {
  quoteReceived: PropTypes.bool,
  quoteSelection: PropTypes.object,
  clearState: PropTypes.func,
  cookieSet: PropTypes.bool,
  noMenu: PropTypes.bool,
}

const mapStateToProps = state => ({
  quoteReceived: state.quoteReceived,
  quoteSelection: state.quoteSelection,
  reset: state.resetNow,
  name: state.name,
  email: state.email,
  cookieSet: state.cookieSet,
  noMenu: false,
})


const mapDispatchToProps = dispatch => ({
  saveQuote: (quote) => dispatch(saveQuoteToStore(quote)),
  savePersonalData: (data) => dispatch(savePersonalDataToStore(data)),
  clearState: (state) => dispatch(clearState(state)),
  getCookiesConsent: () => {
    dispatch(
      {type: ACCEPT_COOKIES}
    )
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(
  HeroHeaderComponent
);
