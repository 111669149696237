import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import image from '../../../../assets/icons/tick.svg';
import { noop, hexToRgbA } from '../../../../utils';

const Label = styled.label`
  order: ${props => {
    let newOrder = props.value > 31
      ? 4
      : 0
    newOrder += props.value % 4;
    return props.showMore
      ? newOrder
      : 0
  }};
`

const BaseColorFieldSelected = styled.div`
  border: 1px solid ${(props) => (props.isSelected
    ? 'grey'
    : 'transparent')};
  padding: 4px;
`
const BaseColorTick = styled.div`
background: ${props => (props.isSelected
    ? `url(${image})`
    : 'none')} no-repeat;
  background-size: 15px 12px;
  background-position: center;
  z-index: 10;
  width: 34px;
  height:34px;
  position: absolute;
`

const BaseColorField = styled.div`
  // background-color: ${props => props.color}};
  height: 34px;
  width: 34px;
  background:
    linear-gradient(
            0deg,
            ${props => hexToRgbA(props.color, 0.7)},
            ${props => hexToRgbA(props.color, 0.7)}),
    url('https://o-takahata.jp/shop/html/upload/save_image/0523225820_59243ffc7e1c1.jpg');
  background-repeat: no-repeat;
  background-size: contain;
`
const BasePalette = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`

export const BaseColorCheckBox = ({
  isSelected = false,
  value,
  children,
  name,
  colorClick,
  color,
  key,
  showMore,
}) => <Label value={value} showMore={showMore}>
    <input
      style={{ display: 'none' }}
      type="checkbox"
      value={value}
      checked={isSelected}
      name={name}
      onChange={colorClick}
      key={key}
    />
    {children}
    <BaseColorFieldSelected isSelected={isSelected}>
      <BaseColorField color={color} value={value} isSelected={isSelected} >
        <BaseColorTick isSelected={isSelected} />
      </BaseColorField >
    </BaseColorFieldSelected>
  </Label>;

BaseColorCheckBox.propTypes = {
  isSelected: PropTypes.bool,
  value: PropTypes.number,
  children: PropTypes.node,
  name: PropTypes.string,
  colorClick: PropTypes.func,
  color: PropTypes.string,
  showMore: PropTypes.bool,
}

BaseColorCheckBox.defaultProps = {
  isSelected: false,
  value: 0,
  // children: {},
  name: '',
  colorClick: noop,
  color: '',
  showMore: false,
}

const BaseColorCheckBoxGroup = ({
  children,
  selectedBaseColor,
  valueName,
  showMore
}) => {
  const baseColorMax = 35;
  const limit = showMore
    ? children.length
    : baseColorMax;
  const childrenRadio = React.Children.map(children, child => React.cloneElement(child, {
    name: valueName,
    selectedBaseColor,
    show: child.key < limit,
  }));

  return <BasePalette>{childrenRadio}</BasePalette>;
}

BaseColorCheckBoxGroup.propTypes = {
  children: PropTypes.node,
  selectedBaseColor: PropTypes.object,
  valueName: PropTypes.string,
  showMore: PropTypes.bool,
}

export default BaseColorCheckBoxGroup;
