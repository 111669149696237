import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import ProductRadioGroup, { ProductRadioButton } from './ProductRadioGroup';
import { noop } from '../../../utils';
import {pixels} from '../../../config/breakpoints';

const ProductSelectorWrapper = styled.div`
  // border: 1px solid black;
  margin-bottom: 20px;

  @media(min-width: ${pixels.mobile}) {
    margin-bottom: 30px;
  }
`
const ProductSelectHeader = styled.div`
  /* Select a product: */
  font-size: 20px;
  color: #212121;
  letter-spacing: 0.3px;
  margin-bottom: 13px;
`

const ProductSelector = ({
  heading,
  productClick,
  productSelect,
  selectedProduct,
}) => <ProductSelectorWrapper>
    <ProductSelectHeader>
      {heading}
    </ProductSelectHeader>
    <ProductRadioGroup valueName="product">
      <ProductRadioButton value="0" productClick={productClick} checked={selectedProduct === 0}>
        {productSelect[0]}
      </ProductRadioButton>
      <ProductRadioButton value="1" productClick={productClick} checked={selectedProduct === 1}>
        {productSelect[1]}
      </ProductRadioButton>
    </ProductRadioGroup >
  </ProductSelectorWrapper>

ProductSelector.propTypes = {
  heading: PropTypes.string,
  productClick: PropTypes.func,
  productSelect: PropTypes.array,
  selectedProduct: PropTypes.number,
}

ProductSelector.defaultProps = {
  heading: '',
  productClick: noop,
  productSelect: [],
  selectedProduct: 0,
}

export default ProductSelector;

