import React from 'react';
import styled from 'styled-components';

import imgUrl from '../../assets/img/heroimage.jpg';
import resources from '../../resources';
import {pixels} from '../../config/breakpoints';

const {panel} = resources.copy;

const HeroContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  background-color: transparent;
`

const HeroContentImage = styled.img`
  width: 100%;
  height: 100%;
  order: 1;
  @media(min-width: ${pixels.tablet}) {
    display: none;
  }
`

const HeroContentText = styled.div`
  padding: 20px;
  order: 2;
  @media(min-width: ${pixels.tablet}) {
    order: 1;
    background-color: transparent;
    z-index: 2;
  }
`

const HeroContentTitle = styled.div`
  // border: 1px solid black;
  margin-bottom: 16px;

  /* Design your own floo: */
  font-family: Lato;
  font-weight: 300;
  color: #111213;
  font-size: 50px;
  line-height: 56px;
  @media(min-width: ${pixels.tablet}) {
    font-size: 5.3em;
    line-height: 90px;
  }
`


const HeroContentDescription = styled.div`
  // border: 1px solid black;
  font-family: Lato;
  font-weight: 300;
  font-size: 16px;
  line-height: 26px;
`


const HeroContent = () => (
  <HeroContentWrapper>
    <HeroContentText>
      <HeroContentTitle>
        {panel.heading}
      </HeroContentTitle>
      <HeroContentDescription>
        {panel.description}
      </HeroContentDescription>
    </HeroContentText>
    <HeroContentImage src={imgUrl} alt={'hero image of mortex product'}/>
  </HeroContentWrapper>
)

export default HeroContent;