import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import SVG from "react-inlinesvg";
import SVGTerrazzo1 from "../../assets/patterns/terrazo1.svg";
import SVGTerrazzo2 from "../../assets/patterns/terrazo2.svg";
import SVGTerrazzo3 from "../../assets/patterns/terrazo3.svg";

const SVGTerrazzo = styled(SVG)`
  width: ${props => props.width};
  //basecolor
  .st0{
    fill: #${props => props.baseColor};
  }
  //stoneColor
  .st1{
    fill: #${props => props.stoneColor[0]};
  }
  .st2{
    fill: #${props => props.stoneColor[1]};
  }
  .st3{
    fill: #${props => props.stoneColor[2]};
  }
`
const SVGMortex = styled.span`
  width: 100%;
// margin: 30px 50px ;
  //basecolor
`
const defaultStoneColor = [
  '#123456',
  '#222222',
  '#333333',
  '#444444',
  '#555555'
]

const ImageSVG = ({ product, baseColor, stoneColors, width }) => {
  if (product === 'Terrazzo') {
    const numOfColors = stoneColors
      ? stoneColors.length
      : 3;
    const base = baseColor
      ? baseColor.productHex
      : '#111111'
    const stone = stoneColors
      ? stoneColors.map(s => s.productHex)
      : defaultStoneColor;


    let terrazzo;
    switch(numOfColors) {
      case 1: terrazzo = SVGTerrazzo1;
        break;
      case 2: terrazzo = SVGTerrazzo2;
        break;
      case 3: terrazzo = SVGTerrazzo3;
        break;
      default: terrazzo = SVGTerrazzo1;
    }

    return <SVGTerrazzo width={width} baseColor={base} stoneColor={stone} src={terrazzo} />;
  }
  const image = require('raw-loader!../../assets/patterns/mortexTest.svg')
  return <SVGMortex baseColor={baseColor} dangerouslySetInnerHTML={{ __html: image }} />;
}

ImageSVG.propTypes = {
  product: PropTypes.string,
  baseColor: PropTypes.object,
  stoneColors: PropTypes.array,
  width: PropTypes.string,
}

ImageSVG.defaultProps = {
  product: '',
  baseColor: {},
  stoneColors: [],
  width: '0',
}


export default ImageSVG;