import React from 'react';
import styled from 'styled-components';

import ContentMedia from './contentMedia';
import ShowCases from './showCases';
import Vision from './vision';

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

const ContentPanel = () => <ContentWrapper>
  <Vision />
  <ContentMedia />
  <ShowCases />
</ContentWrapper>

export default ContentPanel;