import React from 'react';
import styled from 'styled-components';

import resources from '../../resources';
import {pixels} from '../../config/breakpoints';

const { who } = resources.copy;

const VisionWrapper = styled.div`
  padding: 30px 30px;

  background-color: #F3F3F3;
  @media(min-width: ${pixels.tablet}) {
    padding: 80px 150px;
  }
`

const VisionTitle = styled.div`
    /* Who we are: */
    font-family: 'Lato';
    color: #212121;
    letter-spacing: 0;
    text-transform: uppercase;
    margin-bottom: 34px;
    font-size: 18px;
    line-height: 1.44;
  @media(min-width: ${pixels.tablet}) {
    font-size: 16px;
    line-height: 26px;
  }
`

const VisionDescription = styled.div`
    /* We’re crazy about in: */
  font-family: 'Lato';
  font-size: 42px;
  line-height: 1.19;
  letter-spacing: normal;
  color: #212121;
  font-weight: 300;
  @media(min-width: ${pixels.tablet}) {
    font-size: 50px;
    letter-spacing: 0;
    line-height: 56px;
  }
`
const Vision = () => (
  <div>
    <div style={{ position: 'relative', top: -80 }} name="vision" id="vision" />
    <VisionWrapper >
      <VisionTitle>{who.title}</VisionTitle>
      <VisionDescription>{who.subtitle}</VisionDescription>
    </VisionWrapper>
  </div>
)


export default Vision;