import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { PulseLoader as Loader } from 'react-spinners';
import axios from 'axios';

import Modal from '../heroHeader/modal';
import ImageSVG from './imageSVG';

import { noop, calculatePrice, getProductName, hexToRgbA } from '../../utils';
import resources from '../../resources';
import {pixels} from '../../config/breakpoints';

import positive from '../../assets/icons/positive.svg';
import negative from '../../assets/icons/negative.svg';

const ResultsPage = styled.div`
  position: relative;
  display: block;
`

const ResultsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding: 20px;
  @media (min-width: ${pixels.tablet}) {
    flex-direction: row;
    justify-content: space-around;
    padding: 80px 150px;
  }
`

const LoaderWrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  background-color: rgba(255,255,255,0.7);
  display: ${(props) => props.loading
    ? 'flex'
    : 'none'};
  align-items: center;
  justify-content: space-around;
`

const override = css`
`

const Image = styled.div`
  width: 100%;
  height: ${({product}) => product !== 'Terrazzo'? '300px' : '100vw'}; 
  margin: 20px 0;
  overflow: hidden;

  @media(min-width: ${pixels.tablet}) {
    width: 50%;
    margin: 0;
    height: 100vw;
  }
`

const ResultContent = styled.div`
  width: 100%;
  height: 100%;

  @media(min-width: ${pixels.tablet}) {
    width: 40%;
    height: 40%;
  }
`

const ResultHeading = styled.h1`
  /* Terrazzo tri colours: */
  font-size: 40px;
  color: #212121;
  letter-spacing: 0;
  line-height: 48px;
  margin: 0;
`
const ResultAmount = styled.p`
  margin: 0;
`

const ResultSubHeading = styled.h2`
  /* COMPOSITION: */
  font-size: 16px;
  color: #212121;
  letter-spacing: 0;
  line-height: 26px;
  text-transform: uppercase;
`

const ResultDescription = styled.div`
  /* Lorem Ipsum is simpl: */
  font-size: 16px;
  color: #212121;
  letter-spacing: 0;
  line-height: 26px;
  margin: 20px 0 30px 0;
`

const ResultButton = styled.button`
  /* Rectangle Copy 7: */
  background: #31576e;
  width: 100%;
  padding: 20px;
  font-size: 16px;
  color: #FFFFFF;
  letter-spacing: 0;
  margin-bottom: 20px;
  cursor: pointer;
  border-radius: 5px;
  border: none;
  
  &:hover, &:focus {
    background-color: #555555;
  }
`
const ResultResetButton = styled.button`
  /* Rectangle Copy 9: */
  border: 1px solid #31576e;
  width: 100%;
  padding: 20px;
  font-size: 16px;
  letter-spacing: 0;
  color: #31576e;
  background: #ffffff;
  border-radius: 5px;
  &:hover, &:focus {
    color: #555555;
    border: 1px solid #555555;
}
`
const Confirmation = styled.div`
  text-align: center;
  font-size: 30px;
  padding: 50px 30%;

`
const Heading = styled.p`
  font-weight: 700;
`

const SubHeading = styled.p``

const Icon = styled.img`
  // &:before { 
  //   content: 'tick';
  // }
`

// const height = css`
//   height: calc(${props => props.width});
// `

const ImageDiv = styled.div`
  width: ${({width}) => `${width}px`};
  height: ${({width}) => `${width}px`};
  background:
    linear-gradient(
        0deg, 
        ${props => hexToRgbA(`#${props.baseColor.productHex}`, 0.7)},
        ${props => hexToRgbA(`#${props.baseColor.productHex}`, 0.7)}),
    url('https://o-takahata.jp/shop/html/upload/save_image/0523225820_59243ffc7e1c1.jpg');
  background-repeat: no-repeat;
  background-size: contain;
  `

class Results extends Component {

  static propTypes = {
    quote: PropTypes.object,
    history: PropTypes.shape(
      { push: PropTypes.func, }
    ),
    clearState: PropTypes.func,
    email: PropTypes.string,
    savePersonalData: PropTypes.func,
  }

  static defaultProps = {
    quote: {
      'StoneColor': ''
    },
    history: {
      push: noop,
    },
    clearState: noop,
    email: '',
    savePersonalData: noop,
  }

  constructor(props) {
    super(props);

    this.state = {
      showModal: false,
      showLoader: false,
      showConfirmation: false,
      imageWidth: this.imageRef && this.imageRef.current.width,
    }
    this.confirmationRef = React.createRef();
    this.imageRef = React.createRef();
    this.sendEmail = this.sendEmail.bind(this);
    this.reset = this.reset.bind(this);
  }

  componentDidMount() {
    this.setState({imageWidth: this.imageRef.current.clientWidth})
  }
  sendEmail = async () => {
    console.log('sending email function')
    const product = this.props.quote.Product;
    const baseColor = this.props.quote['Base Color'].productName;
    const stoneColor = this.props.quote['Stone Color'];
    const dimension = this.props.quote.Dimension;
    const { email, name } = this.props;

    const quoteDetails = {
      email,
      name,
      price: calculatePrice(dimension, product),
      product,
      baseColor,
      stoneColor,
      dimension,
      imgSrc: '/',
    }

    if (!email || !name) {
      this.setState({ showModal: true, })
      return;
    }
    this.setState({ showLoader: true });
    // FOR DEV await axios.post('http://localhost:8888/.netlify/functions/send-email/send-email',
    await axios.post('.netlify/functions/send-email/send-email',
        quoteDetails
      )
      .then(response => {
        if (response.status === 200) {
          this.setState({ showConfirmation: true, confirmation: true });
        } else {
          this.setState({ showConfirmation: true, confirmation: false });
        }
      })
      .catch(err => {
        this.setState({ showConfirmation: true, confirmation: false })
      })
      .then(setTimeout(() => {
        this.setState({ showLoader: false, showConfirmation: false });
        this.props.emailSent();
      }, 10000))

    this.confirmationRef.current.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
      inline: 'center',
    })
  }

  reset = () => {
    window.scroll({ top: 0, behavior: 'smooth' });
    this.props.clearState(true)
  }

  closeModal = () => {
    this.setState({ showModal: false });
    setTimeout(() => this.sendEmail(), 500);
  }

  render() {
    let stoneColors = [];
    let baseColor = {};
    let product = {};
    let dimension = 0;
    if (this.props.quote) {
      stoneColors = this.props.quote['Stone Color'];
      baseColor = this.props.quote['Base Color'];
      product = this.props.quote.Product;
      dimension = this.props.quote.Dimension;
    } else {
      stoneColors = [];
      baseColor = {};
      product = '';
      dimension = 0;
    }
    const { subtitle, description, button, reset } = resources.copy.results;
    const { confirmation, showLoader, showConfirmation, showModal } = this.state;
    const confirmationCopy = confirmation
      ? resources.copy.results.success
      : resources.copy.results.failure;
    const { heading, subHeading } = confirmationCopy;

    return (
      <div style={{ display: 'block', position: 'relative' }} >
        {showModal &&
          <Modal onClose={this.closeModal}
            setPersonalData={this.props.savePersonalData}
            optOut={false}
            requireName={true}
          />
        }
        {showConfirmation
          ? <div ref={this.confirmationRef} id="confirmation">
            <Confirmation >
              <Icon src={confirmation ? positive : negative} />
              <Heading>{heading}</Heading>
              <SubHeading>{subHeading}</SubHeading>
            </Confirmation>
          </div>
          : <ResultsPage>
            <div style={{
              display: 'block',
              position: 'relative',
            }} id="result">
              <ResultsWrapper showConfirmation={showLoader}>
                <Image product={product} width={window.screen.width} ref={this.imageRef} >
                  {product !== 'Terrazzo'
                    ? <ImageDiv width={this.state.imageWidth} baseColor={baseColor} />
                    : <ImageSVG product={product} stoneColors={stoneColors} baseColor={baseColor} width="100%" />
                  }
                </Image>
                <ResultContent>
                  <ResultHeading>
                    {getProductName(product, baseColor, stoneColors)}
                    <ResultAmount> £ {calculatePrice(dimension, product)}
                    </ResultAmount>
                  </ResultHeading>
                  <ResultSubHeading>
                    {subtitle}
                  </ResultSubHeading>
                  <ResultDescription>
                    {description}
                  </ResultDescription>
                  <ResultButton onClick={this.sendEmail}>
                    {button}
                  </ResultButton>
                  <ResultResetButton onClick={this.reset}>
                    {reset}
                  </ResultResetButton>
                </ResultContent>
              </ResultsWrapper>
            </div>
            <LoaderWrapper loading={showLoader}>
              <Loader
                className={override}
                loading={showLoader}
                sizeUnit={'px'}
                size={40}
                margin={'10px'}
              />
            </LoaderWrapper>
          </ResultsPage>
        }
      </div>
    )
  }
}

export default Results;