import React from 'react';
import styled from 'styled-components';

import ContentMediaItem from './ContentMediaItem';
import resources from '../../../resources';
import terrrazzo from '../../../assets/img/terrazzo1.jpg';
import mortex from '../../../assets/img/mortex1.jpg';

const { copy } = resources;

const ContentMediaWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

const ContentMedia = () => (
  <ContentMediaWrapper id="whatToDo">
    <ContentMediaItem reverse resource={copy.what1} image={mortex} />
    <ContentMediaItem resource={copy.what2} image={terrrazzo} />
  </ContentMediaWrapper>
)


export default ContentMedia;