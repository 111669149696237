import {
  QUOTE_RECEIVED,
  PERSONAL_DATA_RECEIVED,
  CLEAR_STATE,
} from '../constants/action-types';

export const saveQuoteToStore = (quote) => {
  return (dispatch) => {
    dispatch({ type: QUOTE_RECEIVED, quote });
  }
}

export const savePersonalDataToStore = (data) => {
  return (dispatch) => {
    dispatch({ type: PERSONAL_DATA_RECEIVED, data });
  }
}

export const clearState = (state) => {
  return (dispatch) => {
    dispatch({ type: CLEAR_STATE, state });
  }
}