import React, {useState, useEffect} from "react"

import {App as HomePage} from '../containers';
import LayoutIndex from "../component/Layout/LayoutIndex";
import Layout from '../component/Layout/Layout';
import Construction from '../component/construction';
import {size} from '../config/breakpoints';
import {getWidth} from '../utils';

const IndexPage = () => {

  const [width, setWidth] = useState(getWidth());

  useEffect(() => {
    const resizeListener = () => {
      // change width from the state object
      setWidth(getWidth())
    };
    // set resize listener
    if (typeof window !== 'undefined') {
      window.addEventListener('resize', resizeListener);
    }
  })

  return (
    <>
      {width > size.tablet ?
        (<LayoutIndex>
          <HomePage showMenu={true}/>
        </LayoutIndex>
        ) :
        <Layout>
          <HomePage showMenu={false}/>
        </Layout>
      }
    </>

  )
}

const Index = () => {
  const [token, setToken] = useState()
  if(!token) {
    return <Construction login={setToken}/>
  } else {


    return <IndexPage/>
  }
}

export default Index
