import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { noop } from '../../../utils';

const ShowMeMore = styled.div`
  display: inline-flex;
  flex-direction: row; 
  flex-wrap: nowrap;
  cursor: pointer;
`

const ShowMeMoreText = styled.div`
font-size: 12px;
margin: 0 5px;
`

const ShowMeMoreIcon = styled.span`
background-color: black;
height: 17px;
width: 17px;
border-radius: 50%;
transition: transform 200s;
will-change:auto;
transform: ${props => (props.showMore
    ? 'rotate(deg)'
    : 'rotate(0deg)')};

  &:after{
    position: absolute;
    content: ' ';
    height: 9px;
    width: 1px;
    background-color: #ffffff;
    margin: 4px 8px;
    transform: rotate(45deg);

    transform: ${props => (props.showMore
    ? 'rotate(0deg)'
    : 'rotate(90deg)')};
  }

  &:before{
    position: absolute;
    content: ' ';
    height: 1px;
    width: 9px;
    background-color: #ffffff;
    margin: 8px 4px;
    transform: rotate(45deg);

    transform: ${props => (props.showMore
    ? 'rotate(0deg)'
    : 'rotate(90deg)')};
  }
  

`

const ShowMore = ({ showMore, onClick }) => (
  <ShowMeMore onClick={onClick}>
    <ShowMeMoreText >
      {showMore
        ? 'Show me less'
        : 'Show me more'
      }
    </ShowMeMoreText>
    <ShowMeMoreIcon showMore={showMore} />
  </ShowMeMore>
)
ShowMore.propTypes = {
  showMore: PropTypes.bool,
  onClick: PropTypes.func,
}

ShowMore.defaultProps = {
  showMore: false,
  onClick: noop,
}

export default ShowMore;