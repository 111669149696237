import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import image from '../../../../assets/icons/tick.svg';
import config from '../../../../config';
import { noop } from '../../../../utils';

const stoneColorMax = config.stoneColorMaxShowMore;


const StoneColorFieldSelected = styled.div`
  display: ${props => (props.show
    ? 'inline-block'
    : 'none')};
  opacity: ${props => (props.show
    ? 1
    : 0)};
  background: ${props => (props.isSelected
    ? `url(${image})`
    : 'none')} no-repeat;
  background-size: 15px 12px;
  background-position: center;
  z-index: 10;
  width: 34px;
  height:34px;
  transition: opacity 2s;
`

const StoneColorField = styled.div`
display: ${props => (props.show
    ? 'inline-block'
    : 'none')};
opacity: ${props => (props.show
    ? 1
    : 0)};
transition: opacity 2s;

  background-color: ${props => props.color};
  height: 34px;
  width: 34px;
  border-radius: 50%;
  margin: 2px;
`
const StonePalette = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  height: auto;
  transition: height 2s;
`

export const StoneColorCheckbox = ({
  isSelected = false,
  value,
  name,
  colorClick,
  color,
  show,
}) => <label>
    <input
      style={{ display: 'none' }}
      type="checkbox"
      value={value}
      checked={isSelected}
      name={name}
      onChange={colorClick}
    />
    <StoneColorField color={color} value={value} isSelected={isSelected} show={show}>
      <StoneColorFieldSelected isSelected={isSelected} show={show} />
    </StoneColorField>
  </label>;

StoneColorCheckbox.propTypes = {
  isSelected: PropTypes.bool,
  value: PropTypes.number,
  name: PropTypes.string,
  colorClick: PropTypes.func,
  color: PropTypes.string,
  show: PropTypes.bool,
}

StoneColorCheckbox.defaultProps = {
  isSelected: false,
  value: 0,
  name: '',
  colorClick: noop,
  color: '',
  show: false,
}

const StoneColorCheckBoxGroup = ({
  children,
  // selecteStoneColors,
  valueName,
  showMore
}) => {
  const limit = showMore
    ? children.length
    : stoneColorMax;
  const childrenRadio = React.Children.map(children, child => React.cloneElement(child, { name: valueName, show: (child.key < limit) })
  );

  return <StonePalette>{childrenRadio}</StonePalette>;
}

StoneColorCheckBoxGroup.propTypes = {
  children: PropTypes.node,
  valueName: PropTypes.string,
  showMore: PropTypes.bool,
}

StoneColorCheckBoxGroup.defaultProps = {
  children: {},
  valueName: '',
  showMore: false,
}

export default StoneColorCheckBoxGroup;
