import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import ProductSelector from './productSelector';
import BaseColorSelector from './baseColorSelector';
import StoneColorSelector from './stoneColorSelector';
import DimensionsSelector from './dimensionSelector';
import Modal from '../modal';
import resources from '../../../resources';
import {pixels} from '../../../config/breakpoints';
import config from '../../../config'
import { noop, verifyInput } from '../../../utils';


const { copy } = resources;
const { mortex } = resources;
const { terrazzo } = resources;
const { productSelect, productNaming } = copy.calculator;

const colorBaseMortex = mortex;
const colorBaseTerrazzo = terrazzo.colorBase;
const { colorStone } = terrazzo;

const TERRAZZO_STONE_MAX_COLORS = config.terrazoMaxNum;
const MACRO_STONE_MAX_COLORS = config.mortexMaxNum;

const ProductCalculatorWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: white;
  padding: 40px;
  max-width: 440px;
  border: 1px solid grey;
  border-radius: 5px;
  margin: 19px;
  width: 354px;

  @media (min-width: ${pixels.mobile}) {
    width: 100%;
  }
`

const SubmitButton = styled.button`
  cursor: pointer;
  padding: 20px;
  color: white;
  background-color: #31576e;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  &:hover, &:focus {
    background-color: #31576e;
  }
`

const Divider = styled.hr`
  margin: 0 0 16.5px;
  border: solid 1px #bfbfbf;
  display: ${({product}) => product === 0 ? 'none': 'block'};
  @media(min-width: ${pixels.mobile}) {
    display: none;
  }
`

class ProductCalculator extends Component {

  static propTypes = {
    reset: PropTypes.bool,
    clearState: PropTypes.func,
  }

  static defaultProps = {
    reset: false,
    clearState: noop,
  }

  constructor(props) {
    super(props)
    const array = colorStone.map(() => -1);
    this.state = {
      selectedProduct: 0,
      selectedBaseColor: -1,
      baseColor: '',
      selectedStoneColors: array,
      stoneColors: [],
      dimension: 0,
      product: 'Mortex',
      showMore: false,
      colorBase: colorBaseMortex,
      noDimensionSet: false,
      noBaseColorSet: false,
      noStoneColorSet: false,
    }

    this.productClick = this.productClick.bind(this);
    this.baseClick = this.baseClick.bind(this);
    this.stoneClick = this.stoneClick.bind(this);
    this.handleSlider = this.handleSlider.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.reset !== this.props.reset && nextProps.reset === true) {
      this.setState({
        selectedBaseColor: -1,
        selectedStoneColors: colorStone.map(() => -1),
        dimension: 0,
        noDimensionSet: false,
        noBaseColorSet: false,
        noStoneColorSet: false,
      })
    }
  }

  productClick = (e) => {
    const value = parseInt(e.target.value, 10);
    const color = value === 0
      ? colorBaseMortex
      : colorBaseTerrazzo;
    this.setState({
      selectedProduct: value,
      product: productSelect[value],
      selectedStoneColors: colorStone.map(() => -1),
      selectedBaseColor: -1,
      quoteBaseColor: -1,
      colorBase: color,
      noDimensionSet: false,
      noBaseColorSet: false,
      noStoneColorSet: false,
    })
  }

  baseClick = (param) => {
    this.props.clearState(false);
    const value = this.state.colorBase.findIndex(a => param === a.productNumber);
    this.setState({
      baseColor: this.state.colorBase[value],
      quoteBaseColor: value,
      selectedBaseColor: this.state.colorBase[value].productNumber,
      noBaseColorSet: false,
    });
  }

  stoneClick = (e) => {
    this.props.clearState(false);
    const index = parseInt(e.target.value, 10);
    const { selectedStoneColors, product } = this.state;
    const clickedStones =
      selectedStoneColors.reduce((total, i) => (total + (i === 1
        ? 1
        : 0)));
    if (selectedStoneColors[index] > -1) {
      const selectedNew = selectedStoneColors.map((v, i) => (index === i
        ? -1
        : v));
      this.setState({ selectedStoneColors: selectedNew })
    } else if (
      (product === 'Terrazzo' && clickedStones < TERRAZZO_STONE_MAX_COLORS) ||
      (product === 'Mortex' && clickedStones < MACRO_STONE_MAX_COLORS)
    ) {
      const selectedNew = selectedStoneColors.map((v, i) => (index === i ? 1 : v));
      this.setState({ selectedStoneColors: selectedNew })
    } else {
      this.setState({ error: 'test' });
    }

  }

  handleTextChange = (e) => {
    const input = e.target.value ? e.target.value : 0;
    this.setState({
      dimension: parseInt(input, 10) || 0,
    })
  }

  handleSlider = (e) => {
    this.setState({
      dimension: e,
      noDimensionSet: false,
    })
  }

  showMoreClick = () => {
    this.setState({
      showMore: !this.state.showMore,
    })
  }

  submitResult = () => {
    //add dimension
    const {
      selectedStoneColors,
      selectedProduct,
      dimension
    } = this.state;
    const selectedStones = [];
    selectedStoneColors.forEach((s, i) => {
      if (s > 0) {
        selectedStones.push(colorStone[i]);
      }
    });

    const quoteData = {
      'Product': productSelect[selectedProduct],
      'Base Color': this.state.colorBase[this.state.quoteBaseColor],
      'Stone Color': selectedStones,
      'Dimension': dimension,
    };
    const verified = verifyInput(
      quoteData.Product,
      quoteData.Dimension,
      quoteData['Base Color'],
      quoteData['Stone Color'],
    );
    if (Object.keys(verified).length < 1) {
      this.props.saveQuote(quoteData);
      this.setState({ showModal: true });
    } else {
      this.setState({ ...verified })
    }
  }

  closeModal = () => {
    this.setState({ showModal: false });
    window.scrollTo({ top: typeof window === 'undefined' ? '50%' : window.innerHeight - 100, behavior: 'smooth' });
  }

  render() {
    const {
      productHeading,
      dimensionHeading,
      colorHeading,
      stoneHeading,
    } = copy.calculator;
    const {
      colorBase,
      selectedProduct,
      selectedBaseColor,
      dimension,
      showMore } = this.state;
    return (
      <ProductCalculatorWrapper>
        <ProductSelector
          selectedProduct={selectedProduct}
          productSelect={productNaming}
          productClick={this.productClick}
          heading={productHeading}
        />
        <Divider product={selectedProduct}></Divider>
        <BaseColorSelector
          product={selectedProduct}
          heading={colorHeading}
          selectedBaseColor={selectedBaseColor}
          colorBase={colorBase}
          colorClick={this.baseClick}
          showMore={showMore}
          showMoreClick={selectedProduct === 0
            ? this.showMoreClick
            : noop}
          error={this.state.noBaseColorSet}
        />
        <Divider product={selectedProduct}></Divider>

        {selectedProduct === 1 &&
          <StoneColorSelector
            heading={stoneHeading}
            selectedStoneColors={this.state.selectedStoneColors}
            colorStone={colorStone}
            stoneClick={this.stoneClick}
            showMore={showMore}
            showMoreClick={this.showMoreClick}
            error={this.state.noStoneColorSet}
          />
        }
        <DimensionsSelector
          heading={dimensionHeading}
          handleTextChange={this.handleTextChange}
          handleSlider={this.handleSlider}
          dimension={dimension}
          error={this.state.noDimensionSet}
        />
        <SubmitButton onClick={this.submitResult} >
          {copy.calculator.button}
        </SubmitButton>
        {
          this.state.showModal &&
          <Modal
            onClose={this.closeModal}
            setPersonalData={this.props.savePersonalData}
            optOut={true}
          />
        }
      </ProductCalculatorWrapper>
    )
  }
}
export default ProductCalculator;