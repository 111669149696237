import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import ShowMore from './showMore';
import BaseColorCheckBoxGroup, {BaseColorCheckBox} from './BaseColorCheckBoxGroup'
import resources from '../../../resources';
import {noop} from '../../../utils';

const {baseColorSet: errorMsg} = resources.copy.error;

const BaseColorSelectorWrapper = styled.div`
  // border: 1px solid black;
  margin-bottom: 25px;
`
const BaseColorHeading = styled.div`
  margin-bottom: ${props => (props.error
  ? 0
  : '14px')};
  font-size: 20px;
`
const Error = styled.p`
  color: red;
  font-size: 13px;
  margin: 0 0 5px;
`
const BaseColorSelector = ({
                             product,
                             heading,
                             colorBase,
                             selectedBaseColor,
                             colorClick,
                             showMore,
                             showMoreClick,
                             error
                           }) => <BaseColorSelectorWrapper>
  <BaseColorHeading error={error}>
    {product === 0 ? '' : 'Base '}{heading}
  </BaseColorHeading>
  {error && <Error>{errorMsg}</Error>}
  <BaseColorCheckBoxGroup valueName="baseColor" showMore={showMore}>
    {
      colorBase.filter((e, i) => (!showMore && product === 0
        ? i % 4 === 0
        : true)
      ).map((b, index) => (
        <BaseColorCheckBox
          color={`#${b.productHex}`}
          isSelected={selectedBaseColor === b.productNumber}
          key={`${index}-${b.productHex}`}
          id={index}
          value={index}
          colorClick={() => colorClick(b.productNumber)}
          showMore={product === 0
            ? showMore
            : false}>
          {b[index]}
        </BaseColorCheckBox>
      ))
    }
  </BaseColorCheckBoxGroup>
  {
    product === 0 &&
    <ShowMore showMore={showMore} onClick={showMoreClick}/>
  }
</BaseColorSelectorWrapper>

BaseColorSelector.propTypes = {
  product: PropTypes.number,
  heading: PropTypes.string,
  colorBase: PropTypes.array,
  selectedBaseColor: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  colorClick: PropTypes.func,
  showMore: PropTypes.bool,
  showMoreClick: PropTypes.func,
  error: PropTypes.bool,
}

BaseColorSelector.defaultProps = {
  product: 0,
  heading: '',
  colorBase: [],
  selectedBaseColor: '',
  colorClick: noop,
  showMore: false,
  showMoreClick: noop,
  error: false,
}


export default BaseColorSelector;
